import $ from 'jquery';
import slick from 'slick-carousel';

export default class HomepageCarousel {
    constructor() {

    }

    init() {
        this.handleCarousel();
    }

    handleCarousel() {
        $('.homepage-carousel-container, .expo-carousel-container').slick({
            infinite: true,
            swipeToSlide: true,
            dots: true,
            nextArrow: $('.slick-next'),
            prevArrow: $('.slick-back')

          });
    }
} 

$(() => {
	const homepageCarousel = new HomepageCarousel();
	homepageCarousel.init();
});